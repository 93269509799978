<template>
    <div>
        <a
            href="#"
            role="button"
            @click.prevent="setIsModalSubirImagen(true)"
        >
            <slot></slot>
        </a>

        <transition name="fade">
            <div v-if="showModal" class="modal is-active">
                <div class="modal-background" @click="closeModal"></div>
                <div class="modal-card" style="width: 90%">
                    <section class="modal-card-body m-2">
                        <div class="columns">
                            <div class="column is-two-thirds">
                                <input
                                    ref="input"
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    style="display: none;"
                                    @change="setImage"
                                />
                                <div class="block">
                                    <div class="buttons">
                                        <b-button
                                            @click="showFileChooser"
                                            type="is-primary"
                                            icon-left="upload"
                                        >
                                            Subir Imagen
                                        </b-button>
                                        <b-button
                                            @click="cropImage"
                                            type="is-primary"
                                            icon-left="crop"
                                        >
                                            Cortar
                                        </b-button>
                                    </div>
                                </div>
                                <vue-cropper
                                    ref="cropper"
                                    :aspect-ratio="1"
                                    :src="imgSrc"
                                    preview=".preview"
                                />
                            </div>
                            <div class="column">
                                <section class="preview-area">
                                    <p>Vista Previa</p>
                                    <div class="preview t-image" />
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
                <button
                    class="modal-close is-large"
                    aria-label="close"
                    @click="closeModal"
                ></button>
            </div>
        </transition>
    </div>
</template>

<script>
/**
 * This component enconde image on base64
 */

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Message from '@/utils/Message';

export default {
    components: {
        VueCropper
    },
    model: {
        prop: 'img',
        event: 'img-change'
    },
    data() {
        return {
            imgSrc: require('@/assets/images/placeholders/picture.png'),
            cropImg: '',
            data: null
        };
    },
    computed: {
        imgLocal: {
            get: function() {
                return this.img;
            },
            set: function(value) {
                this.$emit('img-change', value);
            }
        },
        showModal() {
            return this.$store.state.ui.modals.subirImagen;
        }
    },
    methods: {
        // Modal
        setIsModalSubirImagen(value) {
            this.$store.dispatch('ui/setIsModalSubirImagen', value);
        },
        closeModal() {
            this.$store.dispatch('ui/setIsModalSubirImagen', false);
        },
        // VueCropper
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.imgLocal = this.cropImg;
            this.closeModal();
            this.$emit('on-cropp');
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
            this.data = JSON.stringify(
                this.$refs.cropper.getCropBoxData(),
                null,
                4
            );
        },
        getData() {
            this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
            this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
            if (!this.data) return;
            this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
            if (!this.data) return;
            this.$refs.cropper.setData(JSON.parse(this.data));
        },
        setImage(e) {
            const file = e.target.files[0];
            if (file.type.indexOf('image/') === -1) {
                alert('Por favor selecciona una imagen');
                return;
            }

            if (file.size > 4 * 1024 * 1024) {
                alert('La imagen no debe exceder los 4MB');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = event => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert();
                Message.showErrorMessage(this, 'Error, FileReader API not supported');
                return;
            }
        },
        showFileChooser() {
            this.$refs.input.click();
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-area {
    width: 307px;
}

.preview-area p:last-of-type {
    margin-top: 1rem;
}
.preview {
    // width: 100%;
    // height: calc(372px * (9 / 16));
    overflow: hidden;
}
</style>
